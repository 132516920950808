import '../../css/button33.css'
import smartbank from '../../images/smartbankimage.png'
import React from "react";



export default function SmartBankProjectDetails() {
    return (
        <>


            <section>
                <div className="innerPageBannerCol" style={{paddingTop: '40px', paddingBottom: '5px'}}>
                    <div className="container">
                        <div className="row g-4 g-md-3  align-items-center">
                            <div className="col-md-6">
                                <div className="bannerContent">
                                    <br/>
                                    <h1 className="xlTitle pb-md-3" style={{textAlign: 'left'}}>Smart Bank</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div style={{textAlign: 'justify'}}>
                        <time dateTime="2020-05-25 12:00:00">
                            <i className="fas fa-calendar-alt mr-2"></i> 20, Dec, 2024
                            &nbsp; <a href={"https://github.com/MehediHasanRaj/smart_bank"}>🔗 Git Link</a>
                        </time>

                    </div>

                </div>

            </section>
            <br/>

            <section>
                <div className="innerPageBannerCol" style={{paddingTop: '0px', paddingBottom: '10px'}}>
                    <div className="container">
                        <div className="row g-4 g-md-3  align-items-center">
                            <div className="col-md-6">
                                <div className="bannerContent">
                                    <h4 style={{textAlign: 'justify'}}>📝 Summary</h4>
                                </div>
                            </div>
                            <hr/>
                        </div>
                        <p style={{textAlign: 'justify'}}>Smart Bank is a digital banking solution built with modern
                            technologies to provide secure, fast, and seamless banking experiences. It leverages
                            cutting-edge authentication mechanisms (JWT), third-party API integrations, and AI-driven
                            support to enhance the user experience. Whether you’re tracking financial activities,
                            generating bank statements, or interacting with customer support,
                            Smart Bank offers a comprehensive and efficient solution for your banking needs.
                        </p>

                    </div>
                </div>
            </section>

            <section>
                <div className="innerPageBannerCol" style={{paddingTop: '0px', paddingBottom: '10px'}}>
                    <div className="container">
                        <div className="row g-4 g-md-3  align-items-center">
                            <div className="col-md-6">
                                <div className="bannerContent">
                                    <h4 style={{textAlign: 'justify'}}>🛠 Tech Stack</h4>
                                </div>
                            </div>
                            <hr/>
                        </div>
                        {/*<div style={{textAlign: 'justify'}}>*/}
                        {/*    <button className={'button-33 me-2'} >java</button>*/}

                        {/*</div>*/}
                        <div style={{textAlign: 'justify'}}>
                            <ul>
                                <li><strong>🔹Backend:</strong> Java (Spring Boot) – For robust and scalable API
                                    development.
                                </li>
                                <li><strong>🔹Authentication:</strong> JWT (JSON Web Token) – Implementing
                                    state-of-the-art authentication and security.
                                </li>
                                <li><strong>🔹Database:</strong> MySQL – Reliable data storage and management.</li>
                                <li><strong>🔹Caching:</strong> Redis – For enhanced performance and faster data
                                    retrieval.
                                </li>
                                <li><strong>🔹Third-Party Integration:</strong> APIs for email services.</li>
                                <li><strong>🔹Documentation:</strong> Swagger UI – Easy-to-use API documentation for
                                    developers.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <br/>


            <section>
                <div className="innerPageBannerCol" style={{paddingTop: '0px', paddingBottom: '10px'}}>
                    <div className="container">
                        <div className="row g-4 g-md-3  align-items-center">
                            <div className="col-md-6">
                                <div className="bannerContent">
                                    <h4 style={{textAlign: 'justify'}}>🌟 Key Features</h4>
                                </div>
                            </div>
                            <hr/>
                        </div>
                        {/*<p style={{textAlign: 'justify'}}>{data.description}*/}
                        {/*</p>*/}
                        {/*this is features documents in HTML format*/}
                        <div style={{textAlign: 'justify'}}>
                            <section>
                                <div>
                                    <h5>💻 Digital Banking Service</h5>
                                    <ol type={"i"}>
                                        <li>Convenient, secure, and accessible banking.</li>
                                    </ol>

                                </div>

                                <div>
                                    <h5>🔒 Highly Secured Authentication</h5>
                                    <ol type={"i"}>
                                        <li>
                                            <strong>📩 Login Alert:</strong> Real-time notifications for login activities
                                            to keep your account secure.
                                        </li>
                                        <li>
                                            <strong>🛡️ Latest JWT Implementation:</strong> Cutting-edge JSON Web Token
                                            technology for seamless and secure authentication.
                                        </li>
                                    </ol>
                                </div>

                                <div>
                                    <h5>🌐 Third-Party API Integration</h5>
                                    <ol type={"i"}>
                                        <li>
                                            <strong>✉️ Email Service:</strong> Effortless communication with external
                                            APIs for an enhanced user experience.
                                        </li>
                                    </ol>
                                </div>

                                <div>
                                    <h5>🤖 AI Implementation for Enhanced Experience</h5>
                                    <ol type={"i"}>
                                        <li>
                                            <strong>💬 Intelligent Chatbot:</strong> 24/7 support providing instant and
                                            personalized assistance.
                                        </li>
                                    </ol>
                                </div>

                                <div>
                                    <h5>📄 PDF Bank Statement Generation</h5>
                                    <ol type={"i"}>
                                        <li>
                                            Quickly generate and download detailed bank statements in PDF format for
                                            easy
                                            financial tracking.
                                        </li>
                                    </ol>

                                </div>

                                <div>
                                    <h5>🚀 Performance Improvements</h5>
                                    <ol type={"i"}>
                                        <li>
                                            <strong>🗄️ Efficient Database Design:</strong> Optimized database structure
                                            for faster data retrieval and improved scalability.
                                        </li>
                                        <li>
                                            <strong>⚡ Caching Implementation (Redis):</strong> Enhanced performance with
                                            in-memory data caching to reduce response times and increase system
                                            efficiency.
                                        </li>
                                    </ol>
                                </div>
                            </section>

                        </div>

                    </div>
                </div>
            </section>
            <br/>

            <section>
                <div className="innerPageBannerCol" style={{paddingTop: '0px', paddingBottom: '10px'}}>
                    <div className="container">
                        <div className="row g-4 g-md-3  align-items-center">
                            <div className="col-md-6">
                                <div className="bannerContent">
                                    <h4 style={{textAlign: 'justify'}}>📷 Demo Images</h4>
                                </div>
                            </div>
                            <hr/>
                        </div>


                        <div>
                            <img src={smartbank} style={{height: '250px'}} alt="..."/>
                        </div>

                    </div>
                </div>
            </section>
            <br/>


            <section>
                <div className="innerPageBannerCol" style={{paddingTop: '0px', paddingBottom: '10px'}}>
                    <div className="container">
                        <div className="row g-4 g-md-3  align-items-center">
                            <div className="col-md-6">
                                <div className="bannerContent">
                                    <h4 style={{textAlign: 'justify'}}>🎥 Demo Video</h4>
                                </div>
                            </div>
                            <hr/>
                        </div>
                        <div>
                            <iframe width="420" height="345"
                                    src={`https://www.youtube.com/watch?v=KJtZARuO3JY&t=1244s`}>
                            </iframe>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}